import React, { useState, useCallback } from 'react'
import styled, { css } from 'styled-components'
import { down } from 'styled-breakpoints'
import { navigate } from '@reach/router'
import * as yup from 'yup'

import { actions } from '@babelcoder/gatsby-theme-base/src/store/reducer'
import {
  useGlobalState,
  useGlobalDispatch,
} from '@babelcoder/gatsby-theme-base/src/components/GlobalStateProvider'

const InputWrapper = styled.div`
  display: flex;
  margin: ${({ theme }) => theme.spacers.normal} 0;
`

const EmailInput = styled.input.attrs({
  type: 'email',
  placeholder: 'example@email.com',
})`
  ${({ theme }) => css`
    width: 100%;
    max-width: 300px;
    font-size: ${theme.fontSizes.normal};
    padding: ${theme.spacers.small} ${theme.spacers.normal};
    color: ${theme.colors.neutral.gray1000};
    border: 0;
    border-top-left-radius: 2rem;
    border-bottom-left-radius: 2rem;
    outline: none;

    &::placeholder {
      color: ${theme.colors.neutral.gray700};
    }
  `}
`

const SubscribeButton = styled.button`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.medium};
    padding: ${theme.spacers.small} ${theme.spacers.normal};
    color: ${theme.colors.neutral.white};
    background-color: ${theme.colors.main.primary};
    border: 0;
    border-top-right-radius: 2rem;
    border-bottom-right-radius: 2rem;
    outline: none;
    cursor: pointer;
  `}
`

const SubscribedMessage = styled.div`
  ${({ theme, centerSubscribedMessage }) => css`
    width: max-content;
    line-height: 1;
    margin-top: ${theme.spacers.normal};
    padding: ${theme.spacers.small} ${theme.spacers.normal};
    color: ${theme.colors.neutral.white};
    background-color: ${theme.colors.main.primary};

    ${centerSubscribedMessage &&
    css`
      margin-left: auto;
      margin-right: auto;
    `}

    ${down('medium')} {
      margin-left: auto;
      margin-right: auto;
    }
  `}
`

const Error = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.main.danger};
    font-size: ${theme.fontSizes.small};
  `}
`

function SubscriptionBox({ className, centerSubscribedMessage = false }) {
  const { user, isAuthLoaded } = useGlobalState()
  const dispatch = useGlobalDispatch()
  const [email, setEmail] = useState('')
  const [error, setError] = useState(null)
  const schema = yup
    .string()
    .email('รูปแบบอีเมล์ไม่ถูกต้อง')
    .required('โปรดระบุอีเมล์')

  const changeEmail = useCallback((event) => setEmail(event.target.value), [
    setEmail,
  ])

  const subscribe = useCallback(async () => {
    try {
      await schema.validate(email)
      dispatch({
        type: actions.showPopupMessage,
        popupMessage: {
          type: 'info',
          title: 'ต้องการการยืนยันตัวตน',
          message: 'โปรดสมัครสมาชิกเพื่อรับข่าวสารจากเว็บ',
        },
      })
      navigate('/auth/sign-up', { state: { email } })
    } catch (error) {
      setError(error.message)
    }
  }, [dispatch, email, schema, setError])

  if (!isAuthLoaded) return null
  if (user)
    return (
      <SubscribedMessage centerSubscribedMessage={centerSubscribedMessage}>
        ขอบคุณสำหรับการติดตาม
      </SubscribedMessage>
    )

  return (
    <>
      <InputWrapper className={className}>
        <EmailInput onChange={changeEmail}></EmailInput>
        <SubscribeButton onClick={subscribe}>Subscribe</SubscribeButton>
      </InputWrapper>
      {error && <Error>{error}</Error>}
    </>
  )
}

export default SubscriptionBox
