import React from 'react'
import styled from 'styled-components'

import SEO from '@babelcoder/gatsby-theme-base/src/components/SEO'
import PageTitle from '@babelcoder/gatsby-theme-base/src/components/PageTitle'
import AllWeeklyHeader from './AllWeeklyHeader'
import AllWeeklyItem from './AllWeeklyItem'
import useWeeklyMeta from '../hooks/useWeeklyMeta'
import useWeeklyPath from '../hooks/useWeeklyPath'
import useWeeklyCoverImage from '../hooks/useWeeklyCoverImage'
import useWeeklySchema from '../hooks/useWeeklySchema'

const StyledPageTitle = styled(PageTitle)`
  margin: ${({ theme }) => theme.spacers.normal} 0;
`

const AllWeeklyWrapper = styled.section`
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  padding: ${({ theme }) => theme.spacers.large};
  padding-top: 0;
`

function AllWeekly({ allWeekly }) {
  const { title, desc } = useWeeklyMeta()
  const { basePath } = useWeeklyPath()
  const cover = useWeeklyCoverImage()
  const getSchema = useWeeklySchema()
  const schema = {
    '@context': 'http://schema.org',
    '@type': 'Blog',
    name: title,
    description: desc,
    url: basePath,
    sameAs: 'https://facebook.com/babelcoder',
    inLanguage: 'th-TH',
    blogPosts: allWeekly.map(({ node: weekly }) => getSchema(weekly)),
  }

  return (
    <>
      <SEO
        title={title}
        type="article"
        urlPath={basePath}
        coverImageWidth={cover.fixed.width}
        coverImageHeight={cover.fixed.height}
        coverImageURL={cover.fixed.src}
        desc={desc}
        schema={schema}
      ></SEO>
      <AllWeeklyHeader></AllWeeklyHeader>
      <StyledPageTitle>ข่าวสารทั้งหมดจากเรา</StyledPageTitle>
      <AllWeeklyWrapper>
        {allWeekly.map(({ node: weekly }) => (
          <AllWeeklyItem key={weekly.issue} {...weekly}></AllWeeklyItem>
        ))}
      </AllWeeklyWrapper>
    </>
  )
}

export default AllWeekly
