import { useCallback } from 'react'

import useSiteMeta from '@babelcoder/gatsby-theme-base/src/hooks/useSiteMeta'
import toSingleSlash from '@babelcoder/gatsby-theme-base/src/utils/to-single-slash'
import useWeeklyPath from './useWeeklyPath'
import useWeeklyCoverImage from './useWeeklyCoverImage'
import useAuthorAndPublisherSchema from '@babelcoder/gatsby-theme-base/src/hooks/useAuthorAndPublisherSchema'

function useWeeklySchema() {
  const { siteUrl } = useSiteMeta()
  const { getWeeklyPath } = useWeeklyPath()
  const cover = useWeeklyCoverImage()
  const { author, publisher } = useAuthorAndPublisherSchema()

  const getSchema = useCallback(
    ({ issue, title, publishedAtDate }) => {
      const path = getWeeklyPath(issue)

      return {
        '@context': 'http://schema.org',
        '@type': 'BlogPosting',
        name: title,
        headline: title,
        author,
        publisher,
        mainEntityOfPage: {
          '@type': 'WebPage',
          '@id': toSingleSlash(`${siteUrl}/${path}`),
        },
        inLanguage: 'th-TH',
        image: toSingleSlash(`${siteUrl}${cover.fixed.src}`),
        url: toSingleSlash(`${siteUrl}${path}`),
        datePublished: publishedAtDate,
        dateModified: publishedAtDate,
      }
    },
    [siteUrl, author, publisher, cover.fixed, getWeeklyPath]
  )

  return getSchema
}

export default useWeeklySchema
