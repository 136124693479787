import React from 'react'
import styled from 'styled-components'
import { down } from 'styled-breakpoints'

import useSiteMeta from '@babelcoder/gatsby-theme-base/src/hooks/useSiteMeta'
import Jumbotron from '@babelcoder/gatsby-theme-base/src/components/Jumbotron'
import HeaderImage from '../../assets/images/header-image.svg'
import SubscriptionBox from './SubscriptionBox'

const HighlightDesc = styled.span`
  color: ${({ theme }) => theme.colors.accent.primaryDarken15};
`

const StyledSubscriptionBox = styled(SubscriptionBox)`
  ${down('medium')} {
    justify-content: center;
  }
`

function AllWeeklyHeader() {
  const { siteName } = useSiteMeta()
  const sectionName = `${siteName} Weekly`

  return (
    <Jumbotron title={sectionName} Image={HeaderImage}>
      ปวดตับกับบทความที่ยาวไปจนตาค้างใช่ไหม{' '}
      <HighlightDesc>
        {sectionName} คือบริการสรุปบทความแนวยาวไปไม่อ่าน (TL;DR)
      </HighlightDesc>{' '}
      บทความไหนยาว เราจะย่อให้ พร้อมส่งตรงสรุปถึงมือคุณผ่านอีเมล์
      เพียงแค่คุณกดติดตามข่าวสารจากเรา
      <StyledSubscriptionBox></StyledSubscriptionBox>
    </Jumbotron>
  )
}

export default AllWeeklyHeader
