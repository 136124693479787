import { graphql, useStaticQuery } from 'gatsby'

import getDefaultOptions from '../utils/default-options'

function useWeeklyMeta() {
  const { sitePlugin } = useStaticQuery(graphql`
    query {
      sitePlugin(name: { eq: "@babelcoder/gatsby-theme-weekly" }) {
        pluginOptions {
          title
          desc
        }
      }
    }
  `)
  const { title, desc } = getDefaultOptions(sitePlugin.pluginOptions)

  return {
    title,
    desc,
  }
}

export default useWeeklyMeta
