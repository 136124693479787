import { graphql, useStaticQuery } from 'gatsby'

function useWeeklyCoverImage() {
  const { cover } = useStaticQuery(graphql`
    query {
      cover: imageSharp(original: { src: { regex: "/weekly-cover/" } }) {
        fixed(width: 730, height: 415, quality: 50, webpQuality: 50) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  `)

  return cover
}

export default useWeeklyCoverImage
