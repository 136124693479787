import { useCallback } from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import getDefaultOptions from '../utils/default-options'
import toSingleSlash from '@babelcoder/gatsby-theme-base/src/utils/to-single-slash'

function useWeeklyPath() {
  const { sitePlugin } = useStaticQuery(graphql`
    query {
      sitePlugin(name: { eq: "@babelcoder/gatsby-theme-weekly" }) {
        pluginOptions {
          basePath
        }
      }
    }
  `)
  const { basePath } = getDefaultOptions(sitePlugin.pluginOptions)
  const path = toSingleSlash(`${basePath}`)
  const getWeeklyPath = useCallback(
    (issue) => toSingleSlash(`${path}/issue-${issue}`),
    [path]
  )

  return {
    basePath,
    path,
    getWeeklyPath,
  }
}

export default useWeeklyPath
