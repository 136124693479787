import React from 'react'
import { graphql } from 'gatsby'

import AllWeekly from '../components/AllWeekly'

function AllWeeklyTemplate({ data: { allWeekly } }) {
  return <AllWeekly allWeekly={allWeekly.edges}></AllWeekly>
}

export default AllWeeklyTemplate

export const pageQuery = graphql`
  query AllWeeklyQuery($skip: Int!, $limit: Int!) {
    allWeekly: allWeeklyYaml(
      sort: { fields: publishedAt, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          title
          publishedAt(locale: "th-TH", formatString: "DD MMM YYYY")
          publishedAtDate: publishedAt
          issue
        }
      }
    }
  }
`
