import React from 'react'
import styled, { css } from 'styled-components'
import { down } from 'styled-breakpoints'

const Wrapper = styled.div`
  background-color: #a7e5d62b;
`

const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    padding: ${theme.spacers.large};

    ${down('medium')} {
      flex-direction: column;
      text-align: center;
    }
  `}
`

const Content = styled.div`
  flex: 2;
  margin-right: ${({ theme }) => theme.spacers.large};

  ${down('medium')} {
    order: 1;
    margin-right: 0;
  }
`

const Title = styled.h2`
  ${({ theme }) => css`
    position: relative;
    font-size: ${theme.fontSizes.xlarge};

    ${down('xsmall')} {
      font-size: ${theme.fontSizes.large};
    }

    &::before {
      position: absolute;
      content: '';
      bottom: 0;
      width: ${theme.fontSizes.large};
      height: 5px;
      border-radius: ${theme.round.large};
      background-color: ${theme.colors.main.primary};

      ${down('medium')} {
        left: 50%;
        transform: translate(-50%);
      }
    }
  `}
`

const Desc = styled.div`
  ${({ theme }) => css`
    margin: ${theme.spacers.normal} 0;
    color: ${theme.colors.neutral.gray2000};
  `}
`

const ImageWrapper = styled.div`
  flex: 1;

  ${down('medium')} {
    order: 0;
    width: 100%;

    & > svg {
      max-width: 200px;
    }
  }
`

function Jumbotron({ title, children, Image }) {
  return (
    <Wrapper>
      <Container>
        <Content>
          <Title>{title}</Title>
          <Desc>{children}</Desc>
        </Content>
        <ImageWrapper>
          <Image></Image>
        </ImageWrapper>
      </Container>
    </Wrapper>
  )
}

export default Jumbotron
