import React from 'react'
import styled, { css } from 'styled-components'
import { up } from 'styled-breakpoints'
import { Link } from 'gatsby'

import useWeeklyPath from '../hooks/useWeeklyPath'

const Wrapper = styled(Link)`
  ${({ theme }) => css`
    display: block;
    margin-bottom: ${theme.spacers.normal};

    ${up('medium')} {
      margin: ${theme.spacers.normal} 0;
    }
  `}
`

const Container = styled.article`
  display: flex;
  flex-direction: column;

  ${up('medium')} {
    flex-direction: row;
  }
`

const IssueWrapper = styled.div`
  ${({ theme }) => css`
    margin: 0 auto;
    margin-bottom: ${theme.spacers.normal};

    ${up('medium')} {
      display: flex;
      flex-direction: column;
      margin: 0;
      padding: ${theme.spacers.normal};
      font-family: ${theme.fontFamily.heading};
      text-align: center;
      background-color: #a7e5d62b;
      border: 3px solid ${theme.colors.main.primary};
    }
  `}
`

const IssueNo = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: auto;
    width: 60px;
    height: 60px;
    font-size: ${theme.fontSizes.large};
    background-color: ${theme.colors.neutral.white};
    color: ${theme.colors.main.primary};
    border-radius: 50%;
    border: 2px solid ${theme.colors.main.primary};

    ${up('medium')} {
      border-color: ${theme.colors.neutral.gray600};
    }
  `}
`

const IssueTitle = styled.div`
  ${({ theme }) => css`
    display: none;
    font-size: ${theme.fontSizes.large};
    color: ${theme.colors.neutral.gray1500};

    ${up('medium')} {
      display: block;
    }
  `}
`

const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${({ theme }) => theme.spacers.normal};
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.neutral.gray1000};
  text-align: center;

  ${up('medium')} {
    text-align: left;
  }
`

const PublishedAt = styled.div`
  ${({ theme }) => css`
    position: relative;
    color: ${({ theme }) => theme.colors.neutral.gray1000};
    margin: 0 auto;
    margin-top: auto;

    &::before,
    &::after {
      position: absolute;
      content: '';
      top: 50%;
      transform: translateY(-50%);
      right: calc(100% + ${theme.spacers.normal});
      width: 10px;
      height: 2px;
      background-color: ${theme.colors.neutral.gray900};
    }

    &::after {
      left: calc(100% + ${theme.spacers.normal});
    }

    ${up('medium')} {
      margin: 0;
      margin-top: auto;

      &::before,
      &::after {
        display: none;
      }
    }
  `}
`

function AllWeeklyItem({ title, issue, publishedAt }) {
  const { getWeeklyPath } = useWeeklyPath()

  return (
    <Wrapper to={getWeeklyPath(issue)}>
      <Container>
        <IssueWrapper>
          <IssueTitle>ฉบับ</IssueTitle>
          <IssueNo>{issue}</IssueNo>
        </IssueWrapper>
        <RightSide>
          <Title>{title}</Title>
          <PublishedAt>{publishedAt}</PublishedAt>
        </RightSide>
      </Container>
    </Wrapper>
  )
}

export default AllWeeklyItem
