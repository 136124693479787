function getDefaultOptions(options = {}) {
  return {
    basePath: '/',
    contentPath: 'data',
    title: 'Weekly',
    desc: 'Weekly',
    ...options,
  }
}

module.exports = getDefaultOptions
